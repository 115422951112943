import axios from '@axios'
import store from '@/store'

export default {
  async postCuponData(token, cupon) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/cupones/buscarCupones`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idCupon: cupon,
      },
    })
    return response
  },
  async postProcesarCuponData(token, id, cupon, observacion) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/cupones/marcaCanjeadosCupones`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuarioCanjea: id,
        idEnvio: cupon,
        observaciones: observacion,
      },
    })
    return response
  },
}
