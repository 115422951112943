<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <validation-observer ref="filter">
            <b-row>
              <b-col cols="12">
                <input
                  ref="uploadImagen"
                  type="file"
                  class="hidden"
                  @change="onFileChange"
                >
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Código de cupon"
                  label-for="cupon"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="codigo cupon"
                    rules="required"
                  >
                    <b-form-input
                      id="cupon"
                      v-model="codigoCupon"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar codigo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="text-right mt-2">
                <b-button
                  variant="primary"
                  class="ml-1"
                  @click="$refs.uploadImagen.click()"
                >
                  <feather-icon
                    icon="UploadIcon"
                    class="mr-50 text-white"
                  />
                  <span class="text-white">Cargar</span>
                </b-button>
                <b-button
                  variant="primary"
                  class="ml-1"
                  @click="buscarCupon()"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50 text-white"
                  />
                  <span class="text-white">Buscar</span>
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-1"
                  @click="limpiarCupon()"
                >
                  <feather-icon
                    icon="FileIcon"
                    class="mr-50"
                  />
                  Limpiar
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="cupon.id!='0'">
      <b-col>
        <b-card title="Detalle cupon">
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-img
                :src="cupon.imagen"
                fluid-grow
              />
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    label="Tipo"
                  >
                    <b-form-input
                      v-model="cupon.tipo"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Titulo"
                  >
                    <b-form-input
                      v-model="cupon.titulo"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Fecha recibido"
                  >
                    <b-form-input
                      v-model="cupon.fechaRecibido"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Fecha canje"
                  >
                    <b-form-input
                      v-model="cupon.fechaCanje"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Leido descripción"
                  >
                    <b-form-input
                      v-model="cupon.leidoDescripcion"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Canje descripción"
                  >
                    <b-form-input
                      v-model="cupon.canjeadoDescripcion"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Nombre"
                  >
                    <b-form-input
                      v-model="cupon.nombre"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Apellido paterno"
                  >
                    <b-form-input
                      v-model="cupon.apellidoPaterno"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Apellido materno"
                  >
                    <b-form-input
                      v-model="cupon.apellidoMaterno"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Número documento"
                  >
                    <b-form-input
                      v-model="cupon.numeroDocumento"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Compañia"
                  >
                    <b-form-input
                      v-model="cupon.compania"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Jefatura"
                  >
                    <b-form-input
                      v-model="cupon.jefatura"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Campaña"
                  >
                    <b-form-input
                      v-model="cupon.campana"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Mensaje cupon"
                  >
                    <b-form-textarea
                      v-model="cupon.mensaje"
                      disabled
                      rows="2"
                      max-rows="6"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <validation-observer ref="filterProcesar">
            <b-row>
              <b-col>
                <b-form-group
                  label="Observación"
                  label-for="observacion"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="observación"
                    rules="required"
                  >
                    <b-form-input
                      id="cupon"
                      v-model="observacionCupon"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar observación"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-col class="text-right mt-2">
                  <b-button
                    variant="primary"
                    class="ml-1"
                    @click="procesarCupon()"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50 text-white"
                    />
                    <span class="text-white">Procesar</span>
                  </b-button>
                </b-col>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BImg,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import QrCode from 'jsqr'

// eslint-disable-next-line import/no-cycle
import cuponService from '@/service/cuponService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BImg,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      required,
      codigoCupon: '',
      observacionCupon: '',
      cupon: {
        id: 0,
        idEnvio: '',
        imagen: '',
        tipo: '',
        titulo: '',
        fechaRecibido: '',
        fechaCanje: '',
        leidoDescripcion: '',
        canjeado: '',
        canjeadoDescripcion: '',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        numeroDocumento: '',
        compania: '',
        campana: '',
        jefatura: '',
        mensaje: '',
      },
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async onFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        // Crear un objeto Image y asignar la URL de la imagen cargada
        const image = new Image()
        image.src = URL.createObjectURL(file)
        this.uploadedImageSrc = image.src

        // Esperar a que la imagen se cargue completamente
        image.onload = () => {
          // Escanear la imagen en busca de un código QR
          this.scanQrCode(image)
        }
      }
    },
    scanQrCode(image) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // Asignar el tamaño del canvas al tamaño de la imagen
      canvas.width = image.width
      canvas.height = image.height

      // Dibujar la imagen en el canvas
      ctx.drawImage(image, 0, 0)

      // Obtener los datos de píxeles de la imagen
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const code = QrCode(imageData.data, imageData.width, imageData.height)

      if (code) {
        const cadena = code.data
        const partes = cadena.split('|')
        if (partes.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.codigoCupon = partes[0]
        } else {
          this.codigoCupon = cadena
        }
      } else {
        console.log('no se encontro qr')
      }
    },
    async buscarCupon() {
      const success = await this.$refs.filter.validate()
      if (success) {
        this.$store.commit('app/UPDATE_ISLOADING', true)
        const response = await cuponService.postCuponData(this.token, this.codigoCupon)
        const { data } = response.data
        this.cupon = {
          id: data.id,
          idEnvio: data.idEnvio,
          imagen: data.imagen,
          tipo: data.tipo,
          titulo: data.titulo,
          fechaCanje: data.fechaCanje,
          fechaRecibido: data.fechaRecibido,
          leidoDescripcion: data.leidoDescripcion,
          canjeado: data.canjeado,
          canjeadoDescripcion: data.canjeadoDescripcion,
          nombre: data.nombre,
          apellidoPaterno: data.apellidoPaterno,
          apellidoMaterno: data.apellidoMaterno,
          numeroDocumento: data.numeroDocumento,
          compania: data.compania,
          campana: data.campana,
          jefatura: data.jefatura,
          mensaje: data.cuponMensaje,
        }
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    async procesarCupon() {
      const success = await this.$refs.filterProcesar.validate()
      if (success) {
        this.$store.commit('app/UPDATE_ISLOADING', true)
        const response = await cuponService.postProcesarCuponData(this.token, this.userData.id, this.cupon.idEnvio, this.observacionCupon)
        console.log(response.data)
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    limpiarCupon() {
      this.observacionCupon = ''
      this.cupon = {
        id: 0,
        idEnvio: '',
        imagen: '',
        tipo: '',
        titulo: '',
        fechaRecibido: '',
        fechaCanje: '',
        leidoDescripcion: '',
        canjeado: '',
        canjeadoDescripcion: '',
        nombre: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        numeroDocumento: '',
        compania: '',
        campana: '',
        jefatura: '',
        mensaje: '',
      }
      this.codigoCupon = ''
    },
  },
}
</script>

<style>

</style>
